import * as React from "react"
import { Helmet } from 'react-helmet'
import Image from "../../components/image"

// markup
const Level2Page = () => {
  return (

    <>
      <Helmet title="Sports Direct - London Oxford Street - Floor 2" defer={false} />
      <Image image="/oxford-st/floor-2.png"/>
    </>

  )
}

export default Level2Page
